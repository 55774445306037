<template>
  <v-row class="section docScroll mt-6" id="step_1_form_fields">
    <SectionColumn
      type="left"
      title="Step 1: Create your checkout form"
      :content="contentLeftColMerchant"
    />
    <SectionColumn type="right" :content="contentRightColMerchant" />
    <SectionColumn type="left" :content="contentLeftColBuyer" />
    <SectionColumn type="right" :content="contentRightColBuyer" />
    <SectionColumn type="left" :content="contentLeftColTransaction" />
    <SectionColumn type="right" :content="contentRightColTransaction" />
    <SectionColumn type="left" :content="contentLeftColTransactionOptions" />
    <SectionColumn type="right" :content="contentRightColTransactionOptions" />
    <SectionColumn type="left" :content="contentLeftColPaymentMethods" />
    <SectionColumn type="right" :content="contentRightColPaymentMethods" />
    <SectionColumn type="full" :content="contentFullRecAndSplit" />
  </v-row>
</template>

<script>
import SectionColumn from "@/components/layout/SectionColumn";

export default {
  name: "StepOneForm",
  data() {
    return {
      contentLeftColMerchant: [
        {
          doc_section_content_id: 4,
          template: "standard",
          data: {
            text: "Create a checkout page with an HTML form, usually hidden, with a number of fields containing all the necessary information needed for Payfast to process the payment. Most shopping cart platforms will have the customer click on a ‘Confirm Order’ or ‘Pay Now’ button to submit the form and be redirected to the Payfast Payment page.",
          },
        },
        {
          doc_section_content_id: 5,
          template: "attributes",
          data: {
            title: "Merchant details",
            items: [
              {
                name: "merchant_id",
                type: "integer, 8 char",
                required: true,
                description:
                  "The Merchant ID as given by the Payfast system. Used to uniquely identify the receiving account. This can be found on the merchant’s settings page.",
              },
              {
                name: "merchant_key",
                type: "string",
                required: true,
                description:
                  "The Merchant Key as given by the Payfast system. Used to uniquely identify the receiving account. This provides an extra level of certainty concerning the correct account as both the ID and the Key must be correct in order for the transaction to proceed. This can be found on the merchant’s settings page.",
              },
              {
                name: "return_url",
                type: "string, no char limit",
                required: false,
                description:
                  "The URL where the user is returned to after payment has been successfully taken.",
              },
              {
                name: "cancel_url",
                type: "string, no char limit",
                required: false,
                description:
                  "The URL where the user should be redirected should they choose to cancel their payment while on the Payfast system.",
              },
              {
                name: "notify_url",
                type: "string, no char limit",
                required: false,
                description:
                  "The URL which is used by Payfast to post the <a href='/docs#step_4_confirm_payment' class='link--text'>Instant Transaction Notifications</a> (ITNs) for this transaction.",
              },
              {
                name: "fica_idnumber",
                type: "integer, 13 char",
                required: false,
                description:
                  "The Fica ID Number provided of the buyer must be a valid South African ID Number.",
              },
            ],
          },
        },
        {
          doc_section_content_id: 6,
          template: "alert",
          data: {
            type: "info",
            msg: "For the notify_url mentioned, a variable can be specified globally on the Merchant’s Payfast account or overridden on a per transaction basis. The value provided during a transaction overrides the global setting.",
          },
        },
      ],
      contentRightColMerchant: [
        {
          doc_section_content_id: 7,
          template: "request",
          data: {
            title: "Merchant Details",
            language: "html",
            code:
              '<input type="hidden" name="merchant_id" value="10000100">\n' +
              '<input type="hidden" name="merchant_key" value="46f0cd694581a">\n' +
              '<input type="hidden" name="return_url" value="https://www.example.com/success">\n' +
              '<input type="hidden" name="cancel_url" value="https://www.example.com/cancel">\n' +
              '<input type="hidden" name="notify_url" value="https://www.example.com/notify">\n',
          },
        },
      ],
      contentLeftColBuyer: [
        {
          doc_section_content_id: 8,
          template: "attributes",
          data: {
            title: "Customer details",
            items: [
              {
                name: "name_first",
                type: "string, 100 char ",
                required: false,
                description: "The customer’s first name.",
              },
              {
                name: "name_last",
                type: "string, 100 char ",
                required: false,
                description: "The customer’s last name.",
              },
              {
                name: "email_address",
                type: "string, 100 char ",
                required: false,
                description: "The customer’s email address.",
              },
              {
                name: "cell_number",
                type: "string, 100 char ",
                required: false,
                description:
                  "The customer’s valid cell number. If the email_address field is empty, and cell_number provided, the system will use the cell_number as the username and auto login the user, if they do not have a registered account",
              },
            ],
          },
        },
      ],
      contentRightColBuyer: [
        {
          doc_section_content_id: 9,
          template: "request",
          data: {
            title: "Customer Details",
            language: "html",
            code:
              '<input type="hidden" name="name_first" value="John">\n' +
              '<input type="hidden" name="name_last" value="Doe">\n' +
              '<input type="hidden" name="email_address" value="john@doe.com">\n' +
              '<input type="hidden" name="cell_number" value="0823456789">',
          },
        },
      ],
      contentLeftColTransaction: [
        {
          doc_section_content_id: 10,
          template: "attributes",
          data: {
            title: "Transaction details",
            items: [
              {
                name: "m_payment_id",
                type: "string, 100 char ",
                required: false,
                description: "Unique payment ID on the merchant’s system.",
              },
              {
                name: "amount",
                type: "decimal",
                required: true,
                description: "The amount which the payer must pay in ZAR.",
              },
              {
                name: "item_name",
                type: "string, 100 char ",
                required: true,
                description:
                  "The name of the item being charged for, or in the case of multiple items the order number.",
              },
              {
                name: "item_description",
                type: "string, 255 char ",
                required: false,
                description:
                  "The description of the item being charged for, or in the case of multiple items the order description.",
              },
              {
                name: "custom_int<1..5>",
                type: "integer, 255 char",
                required: false,
                description:
                  "A series of 5 custom integer variables <em>(custom_int1, custom_int2…)</em> which can be used by the merchant as pass-through variables. They will be posted back to the merchant at the completion of the transaction.",
              },
              {
                name: "custom_str<1..5>",
                type: "string, 255 char ",
                required: false,
                description:
                  "A series of 5 custom string variables <em>(custom_str1, custom_str2…)</em> which can be used by the merchant as pass-through variables. They will be posted back to the merchant at the completion of the transaction.",
              },
            ],
          },
        },
      ],
      contentRightColTransaction: [
        {
          doc_section_content_id: 11,
          template: "request",
          data: {
            title: "Transaction Details",
            language: "html",
            code:
              '<input type="hidden" name="m_payment_id" value="01AB">\n' +
              '<input type="hidden" name="amount" value="100.00">\n' +
              '<input type="hidden" name="item_name" value="Test Item">\n' +
              '<input type="hidden" name="item_description" value="A test product">\n' +
              '<input type="hidden" name="custom_int1" value="2">\n' +
              '<input type="hidden" name="custom_str1" value="Extra order information">\n',
          },
        },
      ],
      contentLeftColTransactionOptions: [
        {
          doc_section_content_id: 12,
          template: "attributes",
          data: {
            title: "Transaction options",
            items: [
              {
                name: "email_confirmation",
                type: "boolean, 1 char",
                required: false,
                description:
                  "Whether to send an email confirmation to the merchant of the transaction. The email confirmation is automatically sent to the payer. 1 = on, 0 = off",
              },
              {
                name: "confirmation_address",
                type: "string, 100 char",
                required: false,
                description:
                  "The email address to send the confirmation email to. This value can be set globally on your account. Using this field will override the value set in your account for this transaction.",
              },
            ],
          },
        },
      ],
      contentRightColTransactionOptions: [
        {
          doc_section_content_id: 13,
          template: "request",
          data: {
            title: "Transaction Options",
            language: "html",
            code:
              '<input type="hidden" name="email_confirmation" value="1">\n' +
              '<input type="hidden" name="confirmation_address" value="john@doe.com">',
          },
        },
      ],
      contentLeftColPaymentMethods: [
        {
          doc_section_content_id: 14,
          template: "attributes",
          data: {
            title: "Payment methods",
            items: [
              {
                name: "payment_method",
                type: "string, 3 char ",
                required: false,
                description:
                  "When this field is set, only the SINGLE payment method specified can be used when the customer reaches Payfast. If this field is blank, or not included, then all available payment methods will be shown.\n" +
                  `<br><br>The values are as follows:<br>
                    <ul>
                    <li>‘ef’  –  EFT</li>
                    <li>‘cc’  –  Credit card</li>
                    <li>‘dc’  –  Debit card</li>
                    <li>’mp’  –  Masterpass Scan to Pay</li>
                    <li>‘mc’  –  Mobicred</li>
                    <li>‘sc’  –  SCode</li>
                    <li>‘ss’  –  SnapScan</li>
                    <li>‘zp’  –  Zapper</li>
                    <li>‘mt’  –  MoreTyme</li>
                    <li>‘rc’  –  Store card</li>
                    <li>‘mu’  –  Mukuru</li>
                    <li>‘ap’  –  Apple Pay</li>
                    <li>‘sp’  –  Samsung Pay</li>
                    <li>‘cp’  –  Capitec Pay</li>
                    </ul>`,
              },
            ],
          },
        },
      ],
      contentRightColPaymentMethods: [
        {
          doc_section_content_id: 15,
          template: "request",
          data: {
            title: "Payment Methods",
            language: "html",
            code: '<input type="hidden" name="payment_method" value="cc">',
          },
        },
      ],
      contentFullRecAndSplit: [
        {
          doc_section_content_id: 16,
          template: "standard",
          data: {
            text:
              // Recurring and split Options
              "<h3>Recurring Billing and Split Payment form options</h3>" +
              "<br /> " +
              "<p>" +
              "<a href='/docs#subscriptions' class='active__text'>(See Recurring Billing subscriptions and Split Payments for additional fields)</a>" +
              "</p>",
          },
        },
      ],
    };
  },
  components: {
    SectionColumn,
  },
};
</script>

<style scoped></style>
