import { render, staticRenderFns } from "./Ports_Ip.vue?vue&type=template&id=423a5912&scoped=true"
import script from "./Ports_Ip.vue?vue&type=script&lang=js"
export * from "./Ports_Ip.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "423a5912",
  null
  
)

export default component.exports